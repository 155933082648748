import React from "react"
import { graphql } from "gatsby"

import StaticPage from "../templates/StaticPage"

const Consulting = ({ data }) => {
  const { html, frontmatter = {} } = data?.markdownRemark || {}

  return (
    <StaticPage
      title={frontmatter.title}
      description={frontmatter.description}
      html={html}
    />
  )
}

export const query = graphql`
  {
    markdownRemark(
      fileAbsolutePath: { regex: "/pages/confirm-subscription/" }
    ) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`

export default Consulting
